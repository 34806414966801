import React, { useEffect, useState } from "react";
import { Badge, Button, Card, CardBody, Row, Col } from "reactstrap";
import { MatchContext } from "../providers/match";
import { checkRoom } from "../utils/services/roommates";
import { Ienums } from "../utils/interface";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../store/users";

function CardRoomType({
  color,
  labelStatus,
  labelText,
  id,
  roomSize,
  part,
  setAvailableRooms,
  group,
}) {
  const navigate = useNavigate();
  const { t, i18n, setNewContextRefresh, setRoomSize, setTypeOfRoom } =
    React.useContext(MatchContext);
  const store = useSelector((state) => state.user);
  const [enabled, setEnabled] = useState(false);
  const dispatch = useDispatch();

  function verifyShow() {
    if (
      roomSize === 2 &&
      !group.find((e) => e.name.toLocaleLowerCase() === "duplo") &&
      group.find(
        (e) =>
          e.name.toLocaleLowerCase() === "triplo" ||
          e.name.toLocaleLowerCase() === "quadruplo"
      )
    ) {
      setEnabled(false);
      return;
    } else if (
      roomSize === 3 &&
      !group.find((e) => e.name.toLocaleLowerCase() === "triplo") &&
      group.find(
        (e) =>
          e.name.toLocaleLowerCase() === "duplo" ||
          e.name.toLocaleLowerCase() === "quadruplo"
      )
    ) {
      setEnabled(false);
      return;
    } else if (
      roomSize === 4 &&
      !group.find((e) => e.name.toLocaleLowerCase() === "quadruplo") &&
      group.find(
        (e) =>
          e.name.toLocaleLowerCase() === "triplo" ||
          e.name.toLocaleLowerCase() === "duplo"
      )
    ) {
      setEnabled(false);
      return;
    } else getRoomStatus();
  }

  const getRoomStatus = () => {
    try {
      checkRoom({
        eventID: id,
        size: roomSize,
      })
        .then((r) => {
          if (r.full === false) {
            setEnabled(true);
            setAvailableRooms(true);
          }
        })
        .catch((r) => {
          // console.log("response error " + labelText, r);
          setEnabled(false);
        });
    } catch (err) {
      setEnabled(false);
    }
  };

  const goToMatch = () => {
    setNewContextRefresh();
    dispatch(reset([]));
    setRoomSize(roomSize);
    setTypeOfRoom(roomSize);
    // linguagem dinamica ------------
    navigate({
      pathname: "/match",
      search: `?idEvent=${id}&idPart=${part}&room=${roomSize}&idiom=${i18n.language}`,
    });
    //window.location.href =
    //"/match" + `?idEvent=${id}&idPart=${part}&room=${roomSize}&idiom=pt-br`;
  };

  useEffect(() => {
    if (group?.length > 0) {
      verifyShow();
    } else getRoomStatus();
  }, [enabled, group]);

  if (enabled)
    return (
      <Card
        className="card-room-item-main"
        style={{
          cursor: enabled ? "pointer" : "default",
        }}
        onClick={enabled ? goToMatch : console.log(".")}
      >
        <CardBody>
          <Row>
            <Col sm={6}>
              <span style={{ color: enabled ? "#495057" : "#a9acb8" }}>
                {labelText}
              </span>
            </Col>
            <Col sm={4}>
              <Badge color={enabled ? "success" : "secondary"}>
                {enabled ? t("Disponível") : t("Indisponível")}
              </Badge>
            </Col>
            <Col sm={2} style={{ textAlign: "right", paddingTop: 5 }}>
              {enabled && <i class="fa-solid fa-chevron-right"></i>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
}
export default CardRoomType;
