import { useContext, useState, useEffect } from "react";
import { Card, CardBody, CardFooter, CardTitle, Col, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { MatchContext } from "../../providers/match";
import CardRoomType from "../../components/RoomType";
import { Ienums } from "../../utils/interface";
import { useNavigate } from "react-router-dom";
import { getRoommatesRoom } from "../../utils/services/roommates";
import { getGruposByLogin } from "../../utils/services/group";
import { reset } from "../../store/users";
import CardError from "../../components/CardError";
// moment
import moment from "moment";

export default function RoomSelect(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    idEvent,
    idParticipant,
    setNewContextRefresh,
    setRoomSize,
    setTypeOfRoom,
    t,
    matchConfig,
    i18n,
    errorConfig,
    errorConfigMsg,
  } = useContext(MatchContext);

  const [loadingGetInfo, setLoadingGetInfo] = useState(true);
  // quartos disponíveis
  const [availableRooms, setAvailableRooms] = useState(false);
  // exibir match a partir da data
  const [showMatch, setShowMatch] = useState(true);
  // grupos
  const [group, setgroup] = useState({});

  async function getParticipantInfo() {
    dispatch(reset([]));

    setLoadingGetInfo(true);

    await getRoommatesRoom(idEvent, idParticipant)
      .then((res) => {
        if (res?.data?.roomSize > 0) {
          dispatch(reset([]));
          setRoomSize(res?.data?.roomSize);
          setTypeOfRoom(res?.data?.roomSize);

          navigate({
            pathname: "/match",
            search: `?idEvent=${idEvent}&idPart=${idParticipant}&room=${res.data.roomSize}&idiom=pt-br`,
          });
          /* window.location.href =
            "/match" +
            `?idEvent=${idEvent}&idPart=${idParticipant}&room=${res.data.roomSize}&idiom=pt-br`; */
        } else {
          setLoadingGetInfo(false);
        }
      })
      .catch((err) => {
        // console.log(err);
        alert("Erro na requisição, atualize a página.");
      });
  }

  async function getGroups() {
    await getGruposByLogin(idEvent, idParticipant)
      .then((res) => {
        if (res?.count > 0) {
          setgroup(res.data);
        }
      })
      .catch((error) => console.log("erro", error));
  }

  function getTextUnavailable() {
    const lang = i18n.language;
    let textUnavailable = "";
    if (lang === "ptBr") {
      textUnavailable = matchConfig?.unavailableRoomsBr;
    }
    if (lang === "en") {
      textUnavailable = matchConfig?.unavailableRoomsEn;
    }
    if (lang === "sp") {
      textUnavailable = matchConfig?.unavailableRoomsSp;
    }

    return textUnavailable;
  }

  function getTextFooter() {
    const lang = i18n.language;
    let textFooter = "";
    if (lang === "ptBr") {
      textFooter = matchConfig?.footerTextBr;
    }
    if (lang === "en") {
      textFooter = matchConfig?.footerTextEn;
    }
    if (lang === "sp") {
      textFooter = matchConfig?.footerTextSp;
    }

    return textFooter;
  }

  function getTextOpening() {
    const lang = i18n.language;
    let textOpening = "";
    if (lang === "ptBr") {
      textOpening = "A escolha de quartos estará disponível a partir do dia";
    } else if (lang === "sp") {
      textOpening =
        "La selección de habitaciones estará disponible a partir del";
    } else textOpening = "Room selection will be available from";

    return textOpening;
  }

  useEffect(() => {
    setNewContextRefresh();
    if (idEvent && idParticipant) {
      getParticipantInfo();
      getGroups();
    }
  }, [idEvent, idParticipant]);
  // console.log(!availableRooms && getTextUnavailable() !== '');

  useEffect(() => {
    if (
      matchConfig?.releaseAt &&
      moment.utc(matchConfig.releaseAt) > moment.utc(new Date())
    ) {
      setShowMatch(false);
    } else setShowMatch(true);
  }, [matchConfig]);

  if (errorConfig) {
    return <CardError message={errorConfigMsg} lang={t} />;
  }

  if (loadingGetInfo)
    return (
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: `${matchConfig.colorBackground}`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  else
    return (
      <div
        className="container-main"
        style={{
          // width: '100%',
          // minHeight: '100vh',
          backgroundColor: `${matchConfig.colorBackground}`,
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
        {/* <a onClick={() => console.log(moment.utc(matchConfig.releaseAt).format('L') > moment.utc(new Date()).format('L'))}>aqui</a> */}
        <div className="room-container-main">
          <Card className="room-card-main">
            {showMatch ? (
              <>
                <CardBody className="room-card-body">
                  <CardTitle className="room-card-title">
                    {t("Selecione a hospedagem")}
                    <hr />
                  </CardTitle>
                  <div className="room-container-items">
                    {!availableRooms
                      ? getTextUnavailable() || t("Quartos Indisponíveis")
                      : null}
                    {idEvent && (
                      <>
                        <CardRoomType
                          labelText={t("Quarto duplo")}
                          roomSize={2}
                          id={idEvent}
                          part={idParticipant}
                          setAvailableRooms={setAvailableRooms}
                          group={group}
                        />
                        <CardRoomType
                          labelText={t("Quarto triplo")}
                          roomSize={3}
                          id={idEvent}
                          part={idParticipant}
                          setAvailableRooms={setAvailableRooms}
                          group={group}
                        />
                        <CardRoomType
                          labelText={t("Quarto quádruplo")}
                          roomSize={4}
                          id={idEvent}
                          part={idParticipant}
                          setAvailableRooms={setAvailableRooms}
                          group={group}
                        />
                      </>
                    )}
                  </div>
                </CardBody>
                {getTextFooter() && (
                  <CardFooter className="footer-text">
                    <Row>
                      <Col>
                        <p>{getTextFooter()}</p>
                      </Col>
                    </Row>
                  </CardFooter>
                )}
              </>
            ) : (
              <div style={{ margin: 20 }}>
                {`${getTextOpening()} ${moment
                  .utc(matchConfig.releaseAt)
                  .format("DD/MM/YYYY")}`}
              </div>
            )}
          </Card>
        </div>
      </div>
    );
}
