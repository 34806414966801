import axios from "axios";
const urlBase =
  "https://gateway-admin-plus.sistemasinteegra.com.br/match-hospedagem-api";

async function getGruposByLogin(eventId, loginId) {
  const config = {
    method: "get",
    // url: `${urlBase}/temp/logingrupos/${loginId}/participant`,
    url: `${urlBase}/logingrupos/${eventId}/event/${loginId}/participant`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getGruposByLogin };
